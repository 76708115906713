<template>
  <div class="b1">
    <ContactForm ref="contactForm"></ContactForm>
    <!-- Top -->
    <div class="b2">      
      <div class="b8"></div>
      <Header></Header>
      <div class="b6">
        <div class="img1">
          <carousel carouselFolder="home-scroll" />
        </div>        
        <div class="b12">
          <div class="b7">        
            <div v-if="!isMobile">
              <div :class="['t2','b9']">Teamwork</div>
              <div :class="['t2','b10']">Collaboration</div>
              <div :class="['t2','b11']">Support</div>
            </div>
            <div class="vspace2"></div>    
            <div >
              <div class="t4">{{p2}}</div>    
              <div class="space1"></div>
              <div :class="['t4','hBtn','btn']" @click="$refs.contactForm.showForm()">Sign Up <img class="img2" src="../../assets/right-arrow.png" /></div>
            </div>   
          </div>        
        </div>
      </div>
      <div class="b8" v-if="!isMobile"></div>
      <div class="b8"></div>
    </div>
    <!-- Insight Interview -->
    <Youtube></Youtube>
    <!-- Our Services -->
    <div class="b100">
      <div class="b101">
        <div class="t5">Our Services</div>        
        <div v-if="!isMobile" :class="['t3','btn1']" @click="dispatch('services','services')">View All</div>
      </div>
      <div class="b102">          
        <div class="b103">
          <div :class="['btn', 't4']">&#8226; Resident Rehabilitation and Support</div>
          <div :class="['btn', 't4']">&#8226; Day Habilitation</div>
          <div :class="['btn', 't4']">&#8226; Respite</div>
          <div :class="['btn', 't4']">&#8226; Wellness Coordination</div>
        </div>
        <div class="b104" ref="b104" v-if="showImg3 && !isMobile">
          <img class="img3" ref="img3" src="../../assets/1.jpg" />
        </div>        
      </div>
      <div class="b105">
        <div v-if="isMobile" :class="['t3','btn1']" @click="dispatch('services','services')">View All</div>
      </div>
    </div> 
    <!-- Quote -->
    <div class="b200"> 
      <div :class="['b201','t5']">{{ quote }}</div>
    </div>
    <!-- Images -->
    <div class="b300">
      <img v-if="!isMobile" class="img4" src="../../assets/home-imgs/1.png" />
      <img class="img4" src="../../assets/home-imgs/2.png" />      
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Carousel from '../../Utilities/Carousel.vue';
import Header from '../HeaderView.vue';
import Footer from '../FooterView.vue';
import ContactForm from '../../Utilities/ContactForm.vue';
import Youtube from '../../Utilities/Youtube.vue';
export default {
  name: 'home',
  components: {
    Carousel,
    Header,
    Footer,
    ContactForm,
    Youtube
  },
  data() {
    return {
      p2: 'Welcome to Full Potential Inc! We offer Medicaid waiver service programs and resources for participants with disabilities.',
      quote: '"...A Good Life, a meaningful life, a life with which we can enjoy the world and live with purpose, can only be built if we do more than live for ourselves." -Eric Greitans',
      showImg3: true,
      minWindowWidth: 20000,      
    }
  },    
    mounted() {
      // window.addEventListener('resize',this.windowSize);     
      // this.windowSize();
      window.addEventListener('load',this.handleResize);
      window.addEventListener("resize", this.handleResize);      
    },
    methods: {
      handleResize() {
        console.log('resize');
        console.log(this.minWindowWidth);
        console.log(window.innerWidth);          
        if(window.innerWidth > this.minWindowWidth) {
          this.minWindowWidth = 20000;
          this.showImg3=true;
        }
        if(this.$refs.b104 == null) return;   
        if(this.$refs.b104.clientWidth === this.$refs.img3.clientWidth) {
          this.minWindowWidth = window.innerWidth;
          this.showImg3 = false;          
        }
      }
    },
    created() {
      // this.handleResize();
      window.scrollTo({
        top:0,
        left:0,
        behavior: 'smooth'
      });
    }
}
</script>

<style scoped>
.b1 {
  width:100%;
  height: fit-content;    
}
.b2 {
  position:relative;
  /* width:100%;
  padding-top:60px; */
  background-color: v-bind(color1);
  /* z-index: 99; */
  /* font-weight:700;
  line-height:1;
  font-size: calc((1.5-1) * 1vw + 1rem);
  padding-bottom:calc(v-bind(margin) * 1.5); */
}
.b3 {
  width:100%;
  height:200px;
  background-color: v-bind(color1);
}
.b5 {
  display:flex;
  position:absolute;
  right:0;  
  height:100%;
  align-items:center;
}
.b6 {
  display:flex;  
  margin-right:v-bind(margin1);
  margin-left:v-bind(margin1);
  margin-top:120px;  
}
.b7 {
  display:flex;
  flex-direction: column;
  justify-content:space-evenly;
  width:calc((100vw - (2 * v-bind(margin1))) * .45);
  /* margin-left:7.5%; */
}
.b12 {
  width: 100%;
  display:flex;
  justify-content: center;
}
.b8 {
  width:100%;
  height:60px;
  background-color: v-bind(color1);
}
.b10 {
  width: 100%;
  text-align:center;
}
.b11 {
  width: 100%;
  text-align: right;
}

/* Services */
.b100 {
  /* background-color: v-bind(color3); */
  display:flex;
  width:100%;
  margin-top:v-bind(margin1);
  margin-bottom:v-bind(margin1);
}
.b101 {  
  display:flex;
  flex-direction: column;
  padding:v-bind(margin1);
  justify-content: space-evenly;  
  width:fit-content !important;
  white-space:nowrap;
}
.b102 {
  position:relative;  
  display:flex;
  justify-content:space-between;
  width:100%;
  padding-top:30px;
  padding-bottom:30px;    
  box-sizing: border-box;
  border-left:2px solid black;
  /* padding:40px; */
  /* justify-content: space-between; */
}
.b103 {  
  /* width:50% !important; */
  margin-left:v-bind(margin1);
  display:flex;
  flex-direction: column;
  justify-content: space-evenly;  
  width:fit-content !important;
  /* align-items:center; */
  white-space:nowrap;
}
.b104 {
  width:100%;
  display:flex;
  justify-content: center;
  align-items: center;
  overflow:hidden;
}

/* Quote */
.b200 {
  width: 100%;
  background-color: v-bind(color2);
  display:flex;
}
.b201 {
  /* padding-top:40px;
  padding-bottom:40px;
  padding-left: v-bind(margin);
  padding-right: v-bind(margin); */
  padding: calc(v-bind(margin1) * 2.5);
  text-align: center;
  color: v-bind(color3);
  font-style: italic;
}
.b203 {
  width:20%;
  margin-left:10%;
  height:100%;
  display:flex;
  flex-direction: column;
  justify-content: center;
  color:white;
}
/* Images */
.b300 {
  display:flex;
  width:100%;
}
.p2 {
  font-size: calc((.8 * 1.2vw + 1rem));
  font-weight:100;
  font-family:'font2';
}



.hBtn {
  width:fit-content;
  margin-top:40px;
  display:flex;
  align-items:center;
  text-decoration: underline; 
  text-decoration-thickness: 1.5px; 
}

.img1{
    /* width:45%; */
    /* max-height:50vh; */
    /* height:100% !important; */
    /* position:relative; */
    /* display:grid; */
    /* grid-template-columns: 1fr; */
    /* height:calc(vh - 180px) !important; */
}
.img2 {
  height: calc(.8 * 1vw + 1rem);
  margin-left:10px;
}
.img3 {
  height: calc(35vh + 1rem);
}
.img4 {
  width:50%;  
}
.showImg {
  display:block;
}
.hideImg {
  display:none;
}

@media only screen and (max-width: 1152px) {
  .b6 {
    flex-direction: column;
    margin-top:70px;

  }
  .b7 {
    width:calc((100vw - (1 * v-bind(margin1))) * .7);
  }
  .b100 {
    flex-direction: column;
  }
  .b101 {
    flex-direction:row;
    width:100% !important;
    padding:0;
  }
  .b102 {
    border:none;
  }
  .b105 {
    width:100%;
    display:flex;
    justify-content: center;
  }
  .b201 {
    padding-left: calc(v-bind(margin1));
    padding-right: calc(v-bind(margin1));
  }
  .img4 {
    width:100%;
  }
  .b300 {
    justify-content: center;
    background-color: v-bind(color2);
  }
  .t5 {
    display: flex;
    align-items: center;
  }
}
</style>