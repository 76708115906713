<template>
  <div class="b1">
    <div class="b2">
      <iframe
        src="https://www.youtube.com/embed/37vsMrYZ0V0?si=Jy9pU5byq1AKbaJm?rel=0&amp;controls=1&amp;autoplay=1&amp;loop=1&amp;playlist=37vsMrYZ0V0"
        title="YouTube video player"
        style="width: 100%; height: 100%; aspect-ratio: 16 / 9; object-fit: contain"    
        frameborder="0"
        rel="0"
        listType="playlist"
        allow="autoplay; accelerometer; modestbranding; encrypted-media; web-share; gyroscope; picture-in-picture"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
    </div>
  </div>
</template>

//
<script>
// import {getIdFromURL} from 'vue-youtube-embed';
// export default {
//   props: ["youtube_url"],
//   data() {
//     return {
//       videoId: ''
//     }
//   },
//   mounted() {
//     // videoId = getIdFromURL(this.props.youtube_url);
//   }
// }
//
</script>

<style scoped>
  .b1 {
    width: 100%;    
    background-color:#696759;
    padding-top: 4%;
    padding-bottom: 4%;
    display:flex;
    justify-content: center;
  }
  .b2 {
    width: 142vh !important;    
    height: 80vh !important;    
    object-fit:contain;            
  }
@media only screen and (max-width: 1152px) {
  .b2 {
    width: 90% !important;
    height: 50.625% !important;
  }
}
</style>